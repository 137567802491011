

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'setting_';

export default {
  path: routePre + '/system',
  name: 'system',
  header: 'system',
  redirect: {
    name: `${pre}index`,
  },
  component: LayoutMain,
  children: [
	
	{
	  path: 'index',
	  name: `${pre}index`,
	  meta: {
	    auth: ['system-index'],
	    title: '主体信息',
	  },
	  component: () => import('@/pages/system/index/index'),
	},
	{
	  path: 'update',
	  name: `${pre}update`,
	  meta: {
	    auth: ['system-index'],
	    title: '修改主体信息',
		activeMenu: routePre + '/system/index',
	  },
	  component: () => import('@/pages/system/index/update'),
	},
	
	{
	  path: 'setting',
	  name: `${pre}setting`,
	  meta: {
	    auth: ['system-setting'],
	    title: '系统配置',
	  },
	  component: () => import('@/pages/system/setting/index'),
	},
	
    {
      path: 'channel',
      name: `${pre}systemChannel`,
      meta: {
        auth: ['system-channel'],
        title: '推量渠道',
      },
      component: () => import('@/pages/system/channel/index'),
    },
	
	
	
	
  ],
};
