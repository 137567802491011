import { getCookies, removeCookies ,setCookies} from '@/libs/util';
export default {
  namespaced: true,
  state: {
    adminInfo: null,
	roleInfo:null,
	companyInfo:null,
	uniqueAuth: [],
	groups:[],
	currentGroupCode:'',
	version:'',
  },
  mutations: {
    adminInfo(state, adminInfo) {
      state.adminInfo = adminInfo;
    },
	roleInfo(state, roleInfo) {
	  state.roleInfo = roleInfo;
	},
	companyInfo(state, companyInfo) {
	  state.companyInfo = companyInfo;
	},
	uniqueAuth(state, uniqueAuth) {
	  state.uniqueAuth = uniqueAuth;
	},
	
	groups(state, groups) {
	  state.groups = groups;
	},
	currentGroupCode(state, currentGroupCode) {
	  state.currentGroupCode = currentGroupCode;
	  setCookies('currentGroupCode',currentGroupCode)
	  
	},
	version(state, version) {
	  state.version = version;
	},
  },
  actions: {
    
  },
};
