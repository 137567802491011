<template>
  <div>
	  <div v-if="pic" class="avatar-uploader">
		<div class="avatar">
			<el-image class='img' :src="pic" :preview-src-list="[pic]"></el-image>
		</div>
		<i class="el-icon-circle-close" @click="delPic"></i>
	  </div>
	  <div v-else>
		  <el-upload
		    class="avatar-uploader"
		    :action="fileUrl"
		  	:headers="header"
		    :show-file-list="false"
		    :on-success="handleSuccess"
		  	:data="uploadData"
		    :before-upload="beforeUpload">
		    <i  class="el-icon-plus avatar-uploader-icon"></i>
		  		
		  </el-upload>
	  </div>
  </div>
</template>

<script>
import { getCookies } from '@/libs/util';
import Setting from '@/setting';
export default {
  model:{
  	prop:'image',
  	event:'change'
  },
  props: {
    fileUrl: {
      type: String,
      default: Setting.apiBaseURL + '/file/upload',
    },
    image:''
  },
  data(){
	return {
		uploadData: {},
		header: {
		  'Authorization': 'Bearer ' + getCookies('token'),
		},
		pic:''
	}  
  },
  watch:{
    image(val){
      this.pic = val
    }
  },
  mounted() {
    
  },
  methods: {
	
    delPic(res, file) {
    	this.pic = ''
    	this.$emit('change','');
    },
	handleSuccess(res, file) {
		this.pic = res.data.src
		this.$emit('change',res.data.src);
	},
	beforeUpload(file) {},
  },
};
</script>

<style scoped lang="scss">
	
.avatar-uploader {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	
	.el-icon-circle-close{
		position: absolute;
		cursor: pointer;
		right: -6px;
		top: -6px;
		color: var(--prev-color-text-error);
		background: #fff;
		border-radius: 50%;
	}
}
.avatar-uploader:hover {
	border-color: var(--prev-color-primary);
}
.avatar-uploader .avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 80px;
	height: 80px;
	line-height: 80px;
	text-align: center;
	cursor: pointer;
}
.avatar {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 6px;
	.img{
		width:100%;
		height:100%;
	}
	
}


</style>
