

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'auth_';

export default {
  path: routePre + '/auth',
  name: 'auth',
  header: 'auth',
  redirect: {
    name: `${pre}index`,
  },
  component: LayoutMain,
  children: [
	
	
	
	{
	  path: 'admin',
	  name: `${pre}admin`,
	  meta: {
	    auth: ['auth-admin'],
	    title: '管理员',
	  },
	  component: () => import('@/pages/auth/admin'),
	},
	
	{
	  path: 'role',
	  name: `${pre}role`,
	  meta: {
	    auth: ['auth-role'],
	    title: '职位管理',
	  },
	  component: () => import('@/pages/auth/role'),
	}
	
	
  ],
};
