

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'client_';

export default {
  path: routePre + '/client',
  name: 'client',
  header: 'client',
  redirect: {
    name: `${pre}index`,
  },
  component: LayoutMain,
  children: [
	
	
	
	{
	  path: 'index',
	  name: `${pre}index`,
	  meta: {
	    auth: ['client-index'],
	    title: '我的客户',
	  },
	  component: () => import('@/pages/client/index/index'),
	},
	
	{
	  path: 'group',
	  name: `${pre}group`,
	  meta: {
	    auth: ['client-group'],
	    title: '推量小组',
	  },
	  component: () => import('@/pages/client/group/index'),
	},
	
	
	
  ],
};
