<template>
	<div class="layout-navbars-breadcrumb-user">
		
		
		<!-- <dark></dark> -->
		
		<div class="layout-navbars-breadcrumb-user-icon" @click="refresh">
		  <i class="el-icon-refresh-right" ></i>
		</div>
		<div class="admin-warp">
			<el-dropdown size="large" :show-timeout="70" @command="onDropdownCommand" trigger="click" :transfer="true">
				<!-- <span class="el-dropdown-link">
				  Dropdown List<el-icon class="el-icon--right"><arrow-down /></el-icon>
				</span> -->
				<div class="loginInfo acea-row row-middle">
					<div class="avatar mr5">
					  <el-avatar
						:src="getAdminInfos.head_pic"
						:size="30" 
					  />
					</div>
					<div class="nickname">{{getAdminInfos.real_name}}</div>
				</div>
				
				<template #dropdown >
				  <el-dropdown-menu>
					<el-dropdown-item command="user">个人信息</el-dropdown-item>
					<el-dropdown-item command="group">我的小组</el-dropdown-item>
					<el-dropdown-item command="logOut">退出登录</el-dropdown-item>
				  </el-dropdown-menu>
				</template>
			  </el-dropdown>
			  
			<user-info ref="userInfos" ></user-info>
			  
		</div>
		<select-group ref="selectGroup" :isSelect="false"></select-group>
	</div>
	
	
</template>

<script>
//import Dark from '@/layout/component/dark.vue';
import userInfo from '@/layout/component/userInfo';
import { AccountLogout } from '@/api/account';
import { removeCookies } from '@/libs/util';
import { Session, Local } from '@/utils/storage.js';
import selectGroup from '@/pages/flow/components/selectGroup';
//import { ElMessageBox } from 'element-plus';
export default {
  name: 'layout',
  components: {
	//Dark,
	userInfo,
	selectGroup
  },
  data() {
    return {
    };
  },
  computed: {
	// 获取登录信息
	getAdminInfos() {
	  return this.$store.state.login.adminInfo;
	},
  },
  watch: {
	
  },
  created() {
  },
  methods: {
	// `dropdown 下拉菜单` 当前项点击
	onDropdownCommand(path) {
	  if (path === 'logOut') {
	    setTimeout(() => {
	      this.$msgbox({
	        closeOnClickModal: false,
	        closeOnPressEscape: false,
	        showCancelButton: true,
	        confirmButtonText: '确定',
	        cancelButtonText: '取消',
			title:'提示',
			message:'此操作将退出登录, 是否继续?',
	        beforeClose: (action, instance, done) => {
	          if (action === 'confirm') {
	            instance.confirmButtonLoading = true;
	            instance.confirmButtonText = '退出中';
	            AccountLogout().then((res) => {
	              done();
	              this.$message.success('您已成功退出');
	              //this.$store.commit('clearAll');
	              removeCookies('token');
	              removeCookies('expires_time');
	              removeCookies('uuid');
	              setTimeout(() => {
	                this.$router.replace({ name: 'login' });
	                instance.confirmButtonLoading = false;
	              }, 1500);
	            });
	          } else {
	            done();
	          }
	        },
	      })
	        .then(() => {
	          // 清除缓存/token等
	          Session.clear();
	          // 使用 reload 时，不需要调用 resetRoute() 重置路由
	          window.location.reload();
	        })
	        .catch(() => {});
	    }, 150);
	  } else if (path === 'user') {
	    this.$refs.userInfos.drawer = true;
	  } else if(path === 'group'){
		this.$refs.selectGroup.modals = true;
		this.$refs.selectGroup.init('我的小组')
	  }else {
	    this.$router.push(path);
	  }
	},
	
	
	refresh() {
	  this.bus.$emit('onTagsViewRefreshRouterView', this.$route.path);
	},
	
	
  },
};
</script>


<style scoped lang="scss">
.layout-navbars-breadcrumb-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &-link {
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    &-photo {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
  }
  &-icon {
    padding: 0 10px;
    cursor: pointer;
    color: var(--prev-bg-topBarColor);
    height: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    &:hover {
      background: var(--prev-color-hover);
      i {
        display: inline-block;
        animation: logoAnimation 0.3s ease-in-out;
      }
    }
  }
  & ::v-deep .el-dropdown {
    color: var(--prev-bg-topBarColor);
    cursor: pointer;
  }
  & ::v-deep .el-badge {
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
  }
  & ::v-deep .el-badge__content.is-fixed {
    top: 12px;
  }
}


.admin-warp{
	padding-right: 20px;
	
	.loginInfo{
		margin-left: 6px;
	}
}

</style>
