<template>
  <div>
    <el-dialog :visible.sync="modals" width="800px" :title="title" :width="isMobile ? '100%' : '500px'">
		
		<el-row>
			<el-col v-bind="grid">
				<el-descriptions title="约定" :column="1" size="large" class="">
				    <el-descriptions-item label="日推量上限">{{group.day_max_count > 0 ?  group.day_max_count : '无限'}}</el-descriptions-item>
				    <el-descriptions-item label="总推量上限">{{group.max_count > 0 ? group.max_count : '无限'}}</el-descriptions-item>
					<el-descriptions-item label="终止日期">{{group.expire_date}}</el-descriptions-item>
					<el-descriptions-item label="删除权限" :span="3"  >
					<span v-if="params.auth_del.indexOf('1') > -1 && params.auth_del.indexOf(2) > -1">推量方 & 接量方</span>
					<span v-else-if="params.auth_del.indexOf('1') > -1">推量方</span>
					<span v-else-if="params.auth_del.indexOf(2) > -1">接量方</span>
					<span v-else>禁止删除</span>
					</el-descriptions-item>
				</el-descriptions>
			</el-col>
			<el-col v-bind="grid">
				<el-descriptions title="约束" :column="1" size="large" class="">
				    <el-descriptions-item label="量账号" v-if="params.unique_id != 0">{{params.unique_id == 2 ? '必填' : '非必填'}}</el-descriptions-item>
				    <el-descriptions-item label="渠道" v-if="params.channel != 0">{{params.channel == 2 ? '必填' : '非必填'}}</el-descriptions-item>
					<el-descriptions-item label="昵称" v-if="params.nickname != 0">{{params.nickname == 2 ? '必填' : '非必填'}}</el-descriptions-item>
					<el-descriptions-item label="姓名" v-if="params.real_name != 0">{{params.real_name == 2 ? '必填' : '非必填'}}</el-descriptions-item>
					<el-descriptions-item label="手机号" v-if="params.mobile != 0">{{params.mobile == 2 ? '必填' : '非必填'}}</el-descriptions-item>
					<el-descriptions-item label="身份证号" v-if="params.idnumber != 0">{{params.idnumber == 2 ? '必填' : '非必填'}}</el-descriptions-item>
					<el-descriptions-item label="QQ" v-if="params.qq != 0">{{params.qq == 2 ? '必填' : '非必填'}}</el-descriptions-item>
					<el-descriptions-item label="邮箱" v-if="params.email != 0">{{params.email == 2 ? '必填' : '非必填'}}</el-descriptions-item>
				</el-descriptions>
			</el-col>
		</el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'constraintDetail',
  
  data() {
    return {
      modals: false,
	  title:'',
	  group:{},
	  params:{
		auth_del:[]
	  },
	  grid: {
	    xl: 12,
	    lg: 12,
	    md: 24,
	    sm: 24,
	    xs: 24,
	  },
    };
  },
  watch: {
    
  },
  computed: {
    ...mapState('media', ['isMobile']),
  },
  components: {  },
  methods: {
	init(group)
	{
		this.group = group
		this.title = group.name+'的【约定】内容'
		this.params = group.params
	},
    
  },
  created() {
  },
};
</script>

<style scoped>

</style>
