<template>
    <div>
		<el-drawer :visible.sync="modals" :title="title" :size="isMobile ? '100%' : 400"  v-loading="spinShow">
			<div v-if="list.length > 0">
				<el-card v-for="(item,index) in list" class="list mb14"  >
				  
				  <div  class="item" :class="currentCode == item.code ? 'active':''">
					  <div class="acea-row row-between-wrapper">
						<div class=" ">
						  <div class="acea-row row-between-wrapper">
							<div class="title line1">{{item.name}}</div>
						  </div>
						  <div class="operate">
							<a @click.stop="viewContstraint(item)">约定</a>
							<el-divider direction="vertical" ></el-divider>  
							<a @click.stop="viewChannel(item,index)">渠道</a>
						  </div>
						</div>
						<div>
							<el-button type="primary" @click="selectGroup(item)" v-if="isSelect && currentCode != item.code">使用</el-button>
						</div>
					  </div>
					  <div class="progress">
						<div class="mt16  acea-row row-between-wrapper">
							<div>今日推量</div>
							<div>{{item.current_today_count}}<span v-if="item.day_max_count > 0"> / {{item.day_max_count}}</span></div>
						</div>
						<el-progress class="mt5" :show-text="false" :percentage="item.today_progress" :color="currentCode == item.code ? '#7ac0a8' : ''" v-if="item.day_max_count > 0"></el-progress>
						<div class="mt10  acea-row row-between-wrapper">
							<div>总推量</div>
							<div>{{item.current_count}}<span v-if="item.max_count > 0"> / {{item.max_count}}</span></div>
						</div>
						<el-progress class="mt5" :show-text="false" :percentage="item.total_progress" :color="currentCode == item.code ? '#7ac0a8' : ''" v-if="item.max_count > 0"></el-progress>
						
					  </div>
					  
					  <div class="channels" v-show="item.viewChannel == true">
						  <el-tag
						    v-for="channel in item.channels"
						    class="mr5 mt5"
						    >
						    {{channel.name}}
						  </el-tag>
					  </div>
					  
				  </div>
				  
				</el-card>
			</div>
			<div v-else class="mt25 mb20">
				<el-empty description="没有加入任何小组"></el-empty>
			</div>
		</el-drawer>
		<constraint-detail ref="constraintDetail"></constraint-detail>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import {loginAdminGroups } from '@/api/flow';
import constraintDetail from '@/pages/client/group/components/constraintDetail'
export default {
  name: 'adminSelectGroup',
  components: {constraintDetail},
  computed: {
    ...mapState('media', ['isMobile']),
  },
  props:{
  	currentCode:'',
	isSelect:true
  },
  data() {
    return {
	  modals:false,
      spinShow: false,
	  title:'',
	  list:[],
    };
  },
  created() {},
  methods: {
    
	selectGroup(group)
	{
		this.modals = false;
		this.$emit('change',group);
	},
    
    init(title = '切换小组') {
	  this.title = title
	  this.getList()
    },
	
	getList() {
	  this.spinShow = true;
	  let that = this;
	  loginAdminGroups()
	    .then(async (res) => {
	      this.list = res.data;
		  if(this.$store.state.login.roleInfo.visibility != 10){
			this.$store.commit('login/groups', this.list);
		  }
	      this.spinShow = false;
	    })
	    .catch((res) => {
	      this.spinShow = false;
	      this.$message.error(res.msg);
	    });
	},
	
	viewChannel(row,index)
	{
		this.$set(this.list[index],'viewChannel',!row.viewChannel)
	},
	
	viewContstraint(group)
	{
		this.$refs.constraintDetail.modals = true;
		this.$refs.constraintDetail.init(group)
	}
  },
};
</script>

<style lang="scss" scoped>

::v-deep .el-card__body, .el-main {
  padding: 0;
  //cursor: pointer;
  position: relative;
}

.list{
	.item{
		padding: 14px 20px;
		.title{
			font-size:15px;
			font-weight: bold;
			width: 250px;
		}
		.message{
			margin-top:6px;
			font-size:12px;
		}
		.operate{
			margin-top:20px;
			font-size:12px;
		}
		.channels{
			padding-top:14px;
			margin-top: 14px;
			font-size:12px;
			border-top: 1px solid var(--prev-color-primary-light-9);
		}
		.progress{
			margin-top: 14px;
			font-size:12px;
			border-top: 1px solid var(--prev-color-primary-light-9);
			color:var(--prev-color-text-secondary)
		}
	}
	// .item:hover{
	// 	color:#fff;
	// 	background-color: var(--prev-color-primary-light-3);
		
	// 	.operate{
	// 		a{
	// 			color: #fff !important;;
	// 		}
	// 	}
	// }
	.item.active{
		color:#fff;
		background-color: var(--prev-color-primary-light-1);
		
		.operate{
			a{
				color: #fff !important;;
			}
		}
		.progress{
			color:#fff;
		}
	}
	
	
	
}



.current{
	color: #f56c6c;
}

</style>
